import { isNullOrWhiteSpace, printf, toText, isNullOrEmpty } from "./.fable/fable-library.3.2.9/String.js";
import { some } from "./.fable/fable-library.3.2.9/Option.js";
import { fromParts, compare } from "./.fable/fable-library.3.2.9/Decimal.js";
import { isMatch } from "./.fable/fable-library.3.2.9/RegExp.js";
import { Union } from "./.fable/fable-library.3.2.9/Types.js";
import { class_type, int32_type, union_type, string_type } from "./.fable/fable-library.3.2.9/Reflection.js";
import { FSharpResult$2 } from "./.fable/fable-library.3.2.9/Choice.js";

export function ConstrainedType_createString(name, ctor, maxLen, str) {
    if (isNullOrEmpty(str)) {
        const msg = toText(printf("%s must not be null or empty"))(name);
        throw (new Error(msg));
    }
    else if (str.length > maxLen) {
        const msg_1 = toText(printf("%s must not be more than %i chars"))(name)(maxLen);
        throw (new Error(msg_1));
    }
    else {
        return ctor(str);
    }
}

export function ConstrainedType_createStringOption(name, ctor, maxLen, str) {
    if (isNullOrEmpty(str)) {
        return void 0;
    }
    else if (str.length > maxLen) {
        const msg = toText(printf("%s must not be more than %i chars"))(name)(maxLen);
        throw (new Error(msg));
    }
    else {
        return some(ctor(str));
    }
}

export function ConstrainedType_createInt(name, ctor, minVal, maxVal, i) {
    if (i < minVal) {
        const msg = toText(printf("%s: Must not be less than %i"))(name)(minVal);
        throw (new Error(msg));
    }
    else if (i > maxVal) {
        const msg_1 = toText(printf("%s: Must not be greater than %i"))(name)(maxVal);
        throw (new Error(msg_1));
    }
    else {
        return ctor(i);
    }
}

export function ConstrainedType_createDecimal(name, ctor, minVal, maxVal, i) {
    if (compare(i, minVal) < 0) {
        const msg = toText(printf("%s: Must not be less than %M"))(name)(minVal);
        throw (new Error(msg));
    }
    else if (compare(i, maxVal) > 0) {
        const msg_1 = toText(printf("%s: Must not be greater than %M"))(name)(maxVal);
        throw (new Error(msg_1));
    }
    else {
        return ctor(i);
    }
}

export function ConstrainedType_createLike(name, ctor, pattern, str) {
    if (isNullOrEmpty(str)) {
        const msg = toText(printf("%s: Must not be null or empty"))(name);
        throw (new Error(msg));
    }
    else if (isMatch(str, pattern)) {
        return ctor(str);
    }
    else {
        const msg_1 = toText(printf("%s: \u0027%s\u0027 must match the pattern \u0027%s\u0027"))(name)(str)(pattern);
        throw (new Error(msg_1));
    }
}

export class Types_String50 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["String50"];
    }
}

export function Types_String50$reflection() {
    return union_type("Fairsy.Domain.Types.String50", [], Types_String50, () => [[["Item", string_type]]]);
}

export class Types_String250 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["String250"];
    }
}

export function Types_String250$reflection() {
    return union_type("Fairsy.Domain.Types.String250", [], Types_String250, () => [[["Item", string_type]]]);
}

export class Types_UnitQuantity extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UnitQuantity"];
    }
}

export function Types_UnitQuantity$reflection() {
    return union_type("Fairsy.Domain.Types.UnitQuantity", [], Types_UnitQuantity, () => [[["Item", int32_type]]]);
}

export class Types_Price extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Price"];
    }
}

export function Types_Price$reflection() {
    return union_type("Fairsy.Domain.Types.Price", [], Types_Price, () => [[["Item", class_type("System.Decimal")]]]);
}

export function Types_UnitQuantityModule_create(qty) {
    if (qty < 1) {
        return new FSharpResult$2(1, "UnitQuantity can not be negative");
    }
    else if (qty > 1000) {
        return new FSharpResult$2(1, "UnitQuantity can not be more than 1000");
    }
    else {
        return new FSharpResult$2(0, new Types_UnitQuantity(0, qty));
    }
}

export function Types_UnitQuantityModule_value(_arg1) {
    return _arg1.fields[0];
}

export function Types_PriceModule_create(price) {
    if (compare(price, fromParts(0, 0, 0, false, 1)) < 0) {
        return new FSharpResult$2(1, "Price can not be negative");
    }
    else if (compare(price, fromParts(100000, 0, 0, false, 1)) > 0) {
        return new FSharpResult$2(1, "Price can not be more than 10 000");
    }
    else {
        return new FSharpResult$2(0, new Types_Price(0, price));
    }
}

export function Types_PriceModule_value(_arg1) {
    return _arg1.fields[0];
}

export function Types_String50Module_create(input) {
    if (isNullOrWhiteSpace(input)) {
        return new FSharpResult$2(1, "empty string");
    }
    else if (input.length > 50) {
        return new FSharpResult$2(1, "Invoer veld mag niet meer dan 250 zijn");
    }
    else {
        return new FSharpResult$2(0, new Types_String50(0, input));
    }
}

export function Types_String50Module_createOption(input) {
    if (input == null) {
        return new FSharpResult$2(1, "empty string");
    }
    else {
        return Types_String50Module_create(input);
    }
}

export function Types_String50Module_value(_arg1) {
    return _arg1.fields[0];
}

export function Types_String250Module_create(input) {
    if (isNullOrWhiteSpace(input)) {
        return new FSharpResult$2(1, "empty string");
    }
    else if (input.length > 250) {
        return new FSharpResult$2(1, "Invoer veld mag niet meer dan 250 zijn");
    }
    else {
        return new FSharpResult$2(0, new Types_String250(0, input));
    }
}

export function Types_String250Module_createOption(input) {
    if (input == null) {
        return new FSharpResult$2(1, "empty string");
    }
    else {
        return Types_String250Module_create(input);
    }
}

export function Types_String250Module_value(_arg1) {
    return _arg1.fields[0];
}

