import { Record, Union } from "./.fable/fable-library.3.2.9/Types.js";
import { record_type, option_type, string_type, class_type, union_type } from "./.fable/fable-library.3.2.9/Reflection.js";
import { decimal, string, guid, object } from "./.fable/Thoth.Json.6.0.0/Decode.fs.js";

export class TaxType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["DutchHigh", "DutchLow", "DutchAlchol"];
    }
}

export function TaxType$reflection() {
    return union_type("Fairsy.Domain.TaxType", [], TaxType, () => [[], [], []]);
}

export class Product_Product extends Record {
    constructor(ProductId, KioskId, ProductTitle, ProductDescription, PricePerPiece, Image, TaxTariff) {
        super();
        this.ProductId = ProductId;
        this.KioskId = KioskId;
        this.ProductTitle = ProductTitle;
        this.ProductDescription = ProductDescription;
        this.PricePerPiece = PricePerPiece;
        this.Image = Image;
        this.TaxTariff = TaxTariff;
    }
}

export function Product_Product$reflection() {
    return record_type("Fairsy.Domain.Product.Product", [], Product_Product, () => [["ProductId", class_type("System.Guid")], ["KioskId", string_type], ["ProductTitle", string_type], ["ProductDescription", string_type], ["PricePerPiece", class_type("System.Decimal")], ["Image", option_type(string_type)], ["TaxTariff", TaxType$reflection()]]);
}

export function Product_Product_get_Decoder() {
    return (path_6) => ((v) => object((get$) => (new Product_Product(get$.Required.Field("ProductId", (path, value) => guid(path, value)), get$.Required.Field("KioskId", (path_1, value_1) => string(path_1, value_1)), get$.Required.Field("ProductTitle", (path_2, value_2) => string(path_2, value_2)), get$.Required.Field("ProductDescription", (path_3, value_3) => string(path_3, value_3)), get$.Required.Field("PricePerPiece", (path_4, value_4) => decimal(path_4, value_4)), get$.Optional.Field("Image", (path_5, value_5) => string(path_5, value_5)), new TaxType(2))), path_6, v));
}

