import {
  Button,
  Input,
  Skeleton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react"
import React, { useContext } from "react"
import CheckoutContext from "../../context/checkoutContext"
import UserContext from "../../context/userContext"
import useBasket from "../../hooks/useBasket"

export const BasketTable = () => {
  const checkout = useContext(CheckoutContext)
  const user = useContext(UserContext)
  const { addToBasket, deleteFromBasket } = useBasket()
  const items = checkout?.state?.validatedBasket ?? user.state?.basket ?? []

  const isLoaded = checkout?.state?.validatedBasket?.length ?? false
  return (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th>Product</Th>
          <Th isNumeric>Stukprijs</Th>
          <Th isNumeric>Aantal</Th>
          <Th isNumeric>totaal prijs</Th>
          <Th>&nbsp;</Th>
        </Tr>
      </Thead>

      <Tbody>
        {items.map(x => (
          <React.Fragment key={x.productId}>
            <Tr>
              <Td>{isLoaded ? x.name : <Skeleton height="1.5rem" />}</Td>
              <Td isNumeric>
                {isLoaded ? (
                  "€ " + x?.pricePerPiece
                ) : (
                  <Skeleton height="1.5rem" />
                )}
              </Td>
              <Td isNumeric>
                <Input
                  type="number"
                  value={x.quantity}
                  disabled={!isLoaded}
                  onChange={input => {
                    const quantity = +input.target.value as number

                    addToBasket({ ...x, quantity })
                    checkout.dispatch({})
                  }}
                />
              </Td>
              <Td isNumeric>
                {isLoaded ? " € " + x?.price : <Skeleton height="1.5rem" />}
              </Td>
              <Td>
                <Button onClick={() => deleteFromBasket(x)}>X</Button>
              </Td>
            </Tr>
          </React.Fragment>
        ))}
      </Tbody>
    </Table>
  )
}
