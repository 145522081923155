import { class_type } from "./.fable/fable-library.3.2.9/Reflection.js";
import { FSharpResult$2 } from "./.fable/fable-library.3.2.9/Choice.js";
import { AsyncResult_bind, AsyncResult_retn, Result_bind } from "./File1.fs.js";
import { getEnumerator, equals } from "./.fable/fable-library.3.2.9/Util.js";
import { singleton } from "./.fable/fable-library.3.2.9/AsyncBuilder.js";
import { startChild } from "./.fable/fable-library.3.2.9/Async.js";

export class ResultComputationExpression_ResultBuilder {
    constructor() {
    }
}

export function ResultComputationExpression_ResultBuilder$reflection() {
    return class_type("Fairsy.Domain.ResultComputationExpression.ResultBuilder", void 0, ResultComputationExpression_ResultBuilder);
}

export function ResultComputationExpression_ResultBuilder_$ctor() {
    return new ResultComputationExpression_ResultBuilder();
}

export function ResultComputationExpression_ResultBuilder__Return_1505(__, x) {
    return new FSharpResult$2(0, x);
}

export function ResultComputationExpression_ResultBuilder__Bind_764BA1D3(__, x, f) {
    return Result_bind()(f)(x);
}

export function ResultComputationExpression_ResultBuilder__ReturnFrom_1505(__, x) {
    return x;
}

export function ResultComputationExpression_ResultBuilder__Zero(this$) {
    return ResultComputationExpression_ResultBuilder__Return_1505(this$);
}

export function ResultComputationExpression_ResultBuilder__Delay_1505(__, f) {
    return f;
}

export function ResultComputationExpression_ResultBuilder__Run_FCFD9EF(__, f) {
    return f();
}

export function ResultComputationExpression_ResultBuilder__While_Z746783BA(this$, guard, body) {
    if (!guard()) {
        return ResultComputationExpression_ResultBuilder__Zero(this$);
    }
    else {
        return ResultComputationExpression_ResultBuilder__Bind_764BA1D3(this$, body(), () => ResultComputationExpression_ResultBuilder__While_Z746783BA(this$, guard, body));
    }
}

export function ResultComputationExpression_ResultBuilder__TryWith_Z570AC55B(this$, body, handler) {
    try {
        return ResultComputationExpression_ResultBuilder__ReturnFrom_1505(this$, body());
    }
    catch (e) {
        return handler(e);
    }
}

export function ResultComputationExpression_ResultBuilder__TryFinally_33907399(this$, body, compensation) {
    try {
        return ResultComputationExpression_ResultBuilder__ReturnFrom_1505(this$, body());
    }
    finally {
        compensation();
    }
}

export function ResultComputationExpression_ResultBuilder__Using_Z50D0D9AD(this$, disposable, body) {
    return ResultComputationExpression_ResultBuilder__TryFinally_33907399(this$, () => body(disposable), () => {
        if (equals(disposable, null)) {
        }
        else {
            let copyOfStruct = disposable;
            copyOfStruct.Dispose();
        }
    });
}

export function ResultComputationExpression_ResultBuilder__For_Z4A06DEED(this$, sequence, body) {
    return ResultComputationExpression_ResultBuilder__Using_Z50D0D9AD(this$, getEnumerator(sequence), (enum$) => ResultComputationExpression_ResultBuilder__While_Z746783BA(this$, () => enum$["System.Collections.IEnumerator.MoveNext"](), ResultComputationExpression_ResultBuilder__Delay_1505(this$, () => body(enum$["System.Collections.Generic.IEnumerator`1.get_Current"]()))));
}

export function ResultComputationExpression_ResultBuilder__Combine_71B5E353(this$, a, b) {
    return ResultComputationExpression_ResultBuilder__Bind_764BA1D3(this$, a, b);
}

export const ResultComputationExpression_result = ResultComputationExpression_ResultBuilder_$ctor();

export function Async_map(f, xA) {
    return singleton.Delay(() => singleton.Bind(xA, (_arg1) => singleton.Return(f(_arg1))));
}

export function Async_retn(x) {
    return singleton.Return(x);
}

export function Async_apply(fA, xA) {
    return singleton.Delay(() => singleton.Bind(startChild(fA), (_arg1) => singleton.Bind(xA, (_arg2) => singleton.Bind(_arg1, (_arg3) => singleton.Return(_arg3(_arg2))))));
}

export function Async_bind(f, xA) {
    return singleton.Bind(xA, f);
}

export class AsyncResultComputationExpression_AsyncResultBuilder {
    constructor() {
    }
}

export function AsyncResultComputationExpression_AsyncResultBuilder$reflection() {
    return class_type("Fairsy.Domain.AsyncResultComputationExpression.AsyncResultBuilder", void 0, AsyncResultComputationExpression_AsyncResultBuilder);
}

export function AsyncResultComputationExpression_AsyncResultBuilder_$ctor() {
    return new AsyncResultComputationExpression_AsyncResultBuilder();
}

export function AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(__, x) {
    return AsyncResult_retn(x);
}

export function AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(__, x, f) {
    return AsyncResult_bind(f, x);
}

export function AsyncResultComputationExpression_AsyncResultBuilder__ReturnFrom_1505(__, x) {
    return x;
}

export function AsyncResultComputationExpression_AsyncResultBuilder__Zero(this$) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(this$);
}

export function AsyncResultComputationExpression_AsyncResultBuilder__Delay_1505(__, f) {
    return f;
}

export function AsyncResultComputationExpression_AsyncResultBuilder__Run_FCFD9EF(__, f) {
    return f();
}

export function AsyncResultComputationExpression_AsyncResultBuilder__While_Z69EA5D96(this$, guard, body) {
    if (!guard()) {
        return AsyncResultComputationExpression_AsyncResultBuilder__Zero(this$);
    }
    else {
        return AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(this$, body(), () => AsyncResultComputationExpression_AsyncResultBuilder__While_Z69EA5D96(this$, guard, body));
    }
}

export function AsyncResultComputationExpression_AsyncResultBuilder__TryWith_Z570AC55B(this$, body, handler) {
    try {
        return AsyncResultComputationExpression_AsyncResultBuilder__ReturnFrom_1505(this$, body());
    }
    catch (e) {
        return handler(e);
    }
}

export function AsyncResultComputationExpression_AsyncResultBuilder__TryFinally_33907399(this$, body, compensation) {
    try {
        return AsyncResultComputationExpression_AsyncResultBuilder__ReturnFrom_1505(this$, body());
    }
    finally {
        compensation();
    }
}

export function AsyncResultComputationExpression_AsyncResultBuilder__Using_Z50D0D9AD(this$, disposable, body) {
    return AsyncResultComputationExpression_AsyncResultBuilder__TryFinally_33907399(this$, () => body(disposable), () => {
        if (equals(disposable, null)) {
        }
        else {
            let copyOfStruct = disposable;
            copyOfStruct.Dispose();
        }
    });
}

export function AsyncResultComputationExpression_AsyncResultBuilder__For_Z37455EC1(this$, sequence, body) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Using_Z50D0D9AD(this$, getEnumerator(sequence), (enum$) => AsyncResultComputationExpression_AsyncResultBuilder__While_Z69EA5D96(this$, () => enum$["System.Collections.IEnumerator.MoveNext"](), AsyncResultComputationExpression_AsyncResultBuilder__Delay_1505(this$, () => body(enum$["System.Collections.Generic.IEnumerator`1.get_Current"]()))));
}

export function AsyncResultComputationExpression_AsyncResultBuilder__Combine_25D06633(this$, a, b) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(this$, a, b);
}

export const AsyncResultComputationExpression_asyncResult = AsyncResultComputationExpression_AsyncResultBuilder_$ctor();

