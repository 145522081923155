import { uncurry, createAtom } from "./.fable/fable-library.3.2.9/Util.js";
import { length, ofArray, empty } from "./.fable/fable-library.3.2.9/List.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./.fable/Fable.Promise.2.2.2/Promise.fs.js";
import { fetch$, Types_RequestProperties } from "./.fable/Fable.Fetch.2.4.0/Fetch.fs.js";
import { promise } from "./.fable/Fable.Promise.2.2.2/PromiseImpl.fs.js";
import { ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Return_1505, ResultComputationExpression_ResultBuilder__Bind_764BA1D3, ResultComputationExpression_ResultBuilder__Delay_1505, ResultComputationExpression_ResultBuilder__Run_FCFD9EF } from "./File1.fs.js";
import { array, fromString } from "./.fable/Thoth.Json.6.0.0/Decode.fs.js";
import { Product_Product_get_Decoder } from "./Products.Types.fs.js";
import { map, exists, forAll, toList } from "./.fable/fable-library.3.2.9/Seq.js";
import { defaultArg } from "./.fable/fable-library.3.2.9/Option.js";
import { newGuid } from "./.fable/fable-library.3.2.9/Guid.js";
import { singleton } from "./.fable/fable-library.3.2.9/AsyncBuilder.js";
import { FSharpResult$2 } from "./.fable/fable-library.3.2.9/Choice.js";
import { startChild, awaitPromise } from "./.fable/fable-library.3.2.9/Async.js";
import { validateBasket } from "./Order.Functions.fs.js";
import { toNumber } from "./.fable/fable-library.3.2.9/Decimal.js";
import { Types_PriceModule_value } from "./Common.Types.fs.js";

export let cache = createAtom(empty());

export function fetchFromServer(host, input) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        console.log("laden");
        const defaultProps = ofArray([new Types_RequestProperties(3, "cors"), new Types_RequestProperties(0, "POST"), new Types_RequestProperties(1, {
            ["Content-Type"]: "application/json",
        }), new Types_RequestProperties(2, JSON.stringify(input))]);
        return fetch$(host + "/api/OrderBasketValidateFunction", defaultProps).then(((_arg1) => (_arg1.text().then(((_arg2) => (Promise.resolve(ResultComputationExpression_ResultBuilder__Run_FCFD9EF(ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Delay_1505(ResultComputationExpression_result, () => {
            let decoder;
            return ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, fromString(uncurry(2, (decoder = Product_Product_get_Decoder(), (path) => ((value) => array(uncurry(2, decoder), path, value)))), _arg2), (_arg3) => {
                const ff = toList(_arg3);
                cache(ff, true);
                return ResultComputationExpression_ResultBuilder__Return_1505(ResultComputationExpression_result, ff);
            });
        })))))))));
    }));
}

export function fetchProducts(host, input) {
    console.log(input);
    if ((length(cache()) > 0) ? forAll((x) => exists((y) => (y.ProductId === defaultArg(x.ProductId, newGuid())), cache()), input) : false) {
        return singleton.Delay(() => singleton.Return(new FSharpResult$2(0, cache())));
    }
    else {
        return singleton.Delay(() => singleton.Bind(awaitPromise(fetchFromServer(host, input)), (_arg1) => singleton.Return(_arg1)));
    }
}

export function validate(callback, host, input) {
    return singleton.Delay(() => singleton.Bind(validateBasket((input_1) => fetchProducts(host, input_1), input), (_arg1) => {
        const validatedBasket = _arg1;
        return singleton.Combine((validatedBasket.tag === 1) ? ((console.log(validatedBasket.fields[0]), singleton.Zero())) : ((callback(Array.from(map((x_1) => ({
            productId: x_1.Product.ProductId,
            kioskId: x_1.Product.KioskId,
            name: x_1.Product.ProductTitle,
            price: toNumber(Types_PriceModule_value(x_1.Price)),
            pricePerPiece: toNumber(x_1.Product.PricePerPiece),
        }), validatedBasket.fields[0]))), singleton.Zero())), singleton.Delay(() => singleton.Return()));
    }));
}

export function validateBasketClientSide(input, callback, host) {
    return startChild(validate(callback, host, toList(input)));
}

