import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
} from "@chakra-ui/react"
import { WindowLocation } from "@reach/router"
import React, { useContext, useState } from "react"
import { BasketInformationForm } from "../components/afrekenen/BasketInformationForm"
import { BasketTable } from "../components/afrekenen/BasketTable"
import SimpleLayout from "../components/simpleLayout"
import { CheckoutProvider } from "../context/checkoutContext"
import UserContext from "../context/userContext"

export default function Basket({ location }: { location: WindowLocation }) {
  const [open, setOpen] = useState(0)
  const { state } = useContext(UserContext)
  console.log(state?.basket)
  const button = <Button onClick={() => setOpen(open + 1)}>Volgende</Button>

  const accordionButtonStyle = { fontSize: "120%" }
  return (
    <CheckoutProvider
      basket={state?.basket ?? []}
      basketHash={state?.basketHash}
    >
      <SimpleLayout title="Basket" location={location} width="1000px">
        <Accordion index={open}>
          <AccordionItem>
            <h2>
              <AccordionButton
                onClick={() => setOpen(0)}
                style={accordionButtonStyle}
              >
                <Box flex="1" textAlign="left">
                  Mandje
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <BasketTable />

              {button}
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton
                onClick={() => setOpen(1)}
                style={accordionButtonStyle}
              >
                <Box flex="1" textAlign="left">
                  Bezorging
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <BasketInformationForm />
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton
                onClick={() => setOpen(2)}
                style={accordionButtonStyle}
              >
                <Box flex="1" textAlign="left">
                  Overzicht
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              {button}
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton
                onClick={() => setOpen(3)}
                style={accordionButtonStyle}
              >
                <Box flex="1" textAlign="left">
                  Betalen
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton
                onClick={() => setOpen(4)}
                style={accordionButtonStyle}
              >
                <Box flex="1" textAlign="left">
                  Klaar
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </SimpleLayout>
    </CheckoutProvider>
  )
}
