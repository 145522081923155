import { useContext } from "react";
import UserContext from "../context/userContext";


export type BasketKey = { productId: string, kioskId: string, quantity: number }

export default function useBasket() {

  const { state, dispatch } = useContext(UserContext)

  return {
    addToBasket: (item: BasketKey) => {
      if (!state) {
        return;
      }
      const otherItems = state.basket.filter(x => !(x.kioskId === item.kioskId && x.productId === item.productId))
      const newItem = { ...item, }
      const basket = [...otherItems, newItem]
      updateLocalStorage(basket);
      dispatch({ basket, basketHash: new Date().toUTCString() })
    },
    deleteFromBasket: (item: BasketKey) => {
      if (!state) {
        return;
      }
      const basket = state.basket.filter(x => !(x.kioskId === item.kioskId && x.productId === item.productId))

      updateLocalStorage(basket);
      dispatch({ basket, basketHash: new Date().toUTCString() })
    },
    isInBasket: (item: BasketKey): boolean => { return state?.basket.some(x => x.kioskId === item.kioskId && x.productId === item.productId) ?? false; }
  };
}
function updateLocalStorage(basket: { productId: string; kioskId: string; quantity: number; }[]) {
  localStorage.setItem("basket", JSON.stringify(basket));
}

