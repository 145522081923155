import { Record } from "./.fable/fable-library.3.2.9/Types.js";
import { list_type, string_type, record_type, int32_type, option_type, class_type } from "./.fable/fable-library.3.2.9/Reflection.js";
import { Product_Product$reflection } from "./Products.Types.fs.js";
import { Types_String250$reflection, Types_String50$reflection, Types_UnitQuantity$reflection, Types_Price$reflection } from "./Common.Types.fs.js";

export class UnValidatedOrderItem extends Record {
    constructor(ProductId, KioskId, Quantity, Price) {
        super();
        this.ProductId = ProductId;
        this.KioskId = KioskId;
        this.Quantity = Quantity;
        this.Price = Price;
    }
}

export function UnValidatedOrderItem$reflection() {
    return record_type("Fairsy.Domain.UnValidatedOrderItem", [], UnValidatedOrderItem, () => [["ProductId", option_type(class_type("System.Guid"))], ["KioskId", option_type(class_type("System.Guid"))], ["Quantity", option_type(int32_type)], ["Price", option_type(class_type("System.Decimal"))]]);
}

export class UnValidatedAddress extends Record {
    constructor(Zipcode, Street, HouseNumber, City, Province) {
        super();
        this.Zipcode = Zipcode;
        this.Street = Street;
        this.HouseNumber = HouseNumber;
        this.City = City;
        this.Province = Province;
    }
}

export function UnValidatedAddress$reflection() {
    return record_type("Fairsy.Domain.UnValidatedAddress", [], UnValidatedAddress, () => [["Zipcode", option_type(string_type)], ["Street", option_type(string_type)], ["HouseNumber", option_type(string_type)], ["City", option_type(string_type)], ["Province", option_type(string_type)]]);
}

export class UnValidatedOrder extends Record {
    constructor(OrderId, Items, Address) {
        super();
        this.OrderId = OrderId;
        this.Items = Items;
        this.Address = Address;
    }
}

export function UnValidatedOrder$reflection() {
    return record_type("Fairsy.Domain.UnValidatedOrder", [], UnValidatedOrder, () => [["OrderId", option_type(class_type("System.Guid"))], ["Items", list_type(UnValidatedOrderItem$reflection())], ["Address", UnValidatedAddress$reflection()]]);
}

export class ValidatedOrderItem extends Record {
    constructor(Product, Price, Quantity) {
        super();
        this.Product = Product;
        this.Price = Price;
        this.Quantity = Quantity;
    }
}

export function ValidatedOrderItem$reflection() {
    return record_type("Fairsy.Domain.ValidatedOrderItem", [], ValidatedOrderItem, () => [["Product", Product_Product$reflection()], ["Price", Types_Price$reflection()], ["Quantity", Types_UnitQuantity$reflection()]]);
}

export class ValidatedAddress extends Record {
    constructor(Zipcode, Street, HouseNumber, City, Province) {
        super();
        this.Zipcode = Zipcode;
        this.Street = Street;
        this.HouseNumber = HouseNumber;
        this.City = City;
        this.Province = Province;
    }
}

export function ValidatedAddress$reflection() {
    return record_type("Fairsy.Domain.ValidatedAddress", [], ValidatedAddress, () => [["Zipcode", Types_String50$reflection()], ["Street", Types_String250$reflection()], ["HouseNumber", Types_String50$reflection()], ["City", Types_String250$reflection()], ["Province", Types_String50$reflection()]]);
}

export class ValidatedOrder extends Record {
    constructor(OrderId, Items, TotalPrice, Address) {
        super();
        this.OrderId = OrderId;
        this.Items = Items;
        this.TotalPrice = TotalPrice;
        this.Address = Address;
    }
}

export function ValidatedOrder$reflection() {
    return record_type("Fairsy.Domain.ValidatedOrder", [], ValidatedOrder, () => [["OrderId", class_type("System.Guid")], ["Items", list_type(ValidatedOrderItem$reflection())], ["TotalPrice", Types_Price$reflection()], ["Address", ValidatedAddress$reflection()]]);
}

export class OrderItemDto extends Record {
    constructor(ProductId, KioskId, Quantity, Price, Name, PricePerPiece) {
        super();
        this.ProductId = ProductId;
        this.KioskId = KioskId;
        this.Quantity = (Quantity | 0);
        this.Price = Price;
        this.Name = Name;
        this.PricePerPiece = PricePerPiece;
    }
}

export function OrderItemDto$reflection() {
    return record_type("Fairsy.Domain.OrderItemDto", [], OrderItemDto, () => [["ProductId", class_type("System.Guid")], ["KioskId", class_type("System.Guid")], ["Quantity", int32_type], ["Price", class_type("System.Decimal")], ["Name", string_type], ["PricePerPiece", class_type("System.Decimal")]]);
}

export class AddressDto extends Record {
    constructor(Zipcode, Street, HouseNumber, City, Province) {
        super();
        this.Zipcode = Zipcode;
        this.Street = Street;
        this.HouseNumber = HouseNumber;
        this.City = City;
        this.Province = Province;
    }
}

export function AddressDto$reflection() {
    return record_type("Fairsy.Domain.AddressDto", [], AddressDto, () => [["Zipcode", string_type], ["Street", string_type], ["HouseNumber", string_type], ["City", string_type], ["Province", string_type]]);
}

export class OrderDto extends Record {
    constructor(OrderId, Items, Address) {
        super();
        this.OrderId = OrderId;
        this.Items = Items;
        this.Address = Address;
    }
}

export function OrderDto$reflection() {
    return record_type("Fairsy.Domain.OrderDto", [], OrderDto, () => [["OrderId", class_type("System.Guid")], ["Items", list_type(OrderItemDto$reflection())], ["Address", AddressDto$reflection()]]);
}

