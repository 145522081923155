import { AsyncResultComputationExpression_AsyncResultBuilder__Return_1505, AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93, AsyncResultComputationExpression_AsyncResultBuilder__Delay_1505, AsyncResultComputationExpression_AsyncResultBuilder__Run_FCFD9EF, ResultComputationExpression_ResultBuilder__Return_1505, ResultComputationExpression_ResultBuilder__Bind_764BA1D3, ResultComputationExpression_ResultBuilder__Delay_1505, ResultComputationExpression_ResultBuilder__Run_FCFD9EF, AsyncResultComputationExpression_AsyncResultBuilder_$ctor, ResultComputationExpression_ResultBuilder_$ctor } from "./Result.fs.js";
import { Types_String50Module_value, Types_String250Module_value, Types_UnitQuantityModule_value, Types_PriceModule_value, Types_UnitQuantityModule_create, Types_PriceModule_create, Types_String250Module_createOption, Types_String50Module_createOption } from "./Common.Types.fs.js";
import { OrderDto, AddressDto, OrderItemDto, ValidatedOrder, ValidatedOrderItem, ValidatedAddress } from "./Order.Types.fs.js";
import { defaultArg } from "./.fable/fable-library.3.2.9/Option.js";
import { op_Division, fromParts, op_Addition, op_Multiply } from "./.fable/fable-library.3.2.9/Decimal.js";
import Decimal from "./.fable/fable-library.3.2.9/Decimal.js";
import { toList, map as map_1, reduce, find } from "./.fable/fable-library.3.2.9/Seq.js";
import { singleton } from "./.fable/fable-library.3.2.9/AsyncBuilder.js";
import { AsyncResult_ofResult, Result_sequence } from "./File1.fs.js";
import { map } from "./.fable/fable-library.3.2.9/List.js";
import { groupBy } from "./.fable/fable-library.3.2.9/Seq2.js";
import { safeHash, equals } from "./.fable/fable-library.3.2.9/Util.js";
import { parse, newGuid } from "./.fable/fable-library.3.2.9/Guid.js";

export const result = ResultComputationExpression_ResultBuilder_$ctor();

export const asyncResult = AsyncResultComputationExpression_AsyncResultBuilder_$ctor();

export function validateAddress(input) {
    const builder$0040 = result;
    return ResultComputationExpression_ResultBuilder__Run_FCFD9EF(builder$0040, ResultComputationExpression_ResultBuilder__Delay_1505(builder$0040, () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(builder$0040, Types_String50Module_createOption(input.Zipcode), (_arg1) => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(builder$0040, Types_String50Module_createOption(input.HouseNumber), (_arg2) => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(builder$0040, Types_String50Module_createOption(input.Province), (_arg3) => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(builder$0040, Types_String250Module_createOption(input.Street), (_arg4) => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(builder$0040, Types_String250Module_createOption(input.City), (_arg5) => ResultComputationExpression_ResultBuilder__Return_1505(builder$0040, new ValidatedAddress(_arg1, _arg4, _arg2, _arg5, _arg3)))))))));
}

export function toValidatedOrderLine(product, order) {
    const builder$0040 = result;
    return ResultComputationExpression_ResultBuilder__Run_FCFD9EF(builder$0040, ResultComputationExpression_ResultBuilder__Delay_1505(builder$0040, () => {
        const quantity = defaultArg(order.Quantity, 0) | 0;
        return ResultComputationExpression_ResultBuilder__Bind_764BA1D3(builder$0040, Types_PriceModule_create(op_Multiply(product.PricePerPiece, new Decimal(quantity))), (_arg1) => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(builder$0040, Types_UnitQuantityModule_create(quantity), (_arg2) => ResultComputationExpression_ResultBuilder__Return_1505(builder$0040, new ValidatedOrderItem(product, _arg1, _arg2))));
    }));
}

export function fetchProductFromUnvalidatedOrderItem(item, products) {
    return find((x) => (x.ProductId === defaultArg(item.ProductId, "00000000-0000-0000-0000-000000000000")), products);
}

export function validateBasket(getProducts, unvalidatedOrderLines) {
    return singleton.Delay(() => singleton.Bind(getProducts(unvalidatedOrderLines), (_arg1) => {
        let builder$0040_1;
        return singleton.Return((builder$0040_1 = result, ResultComputationExpression_ResultBuilder__Run_FCFD9EF(builder$0040_1, ResultComputationExpression_ResultBuilder__Delay_1505(builder$0040_1, () => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(builder$0040_1, _arg1, (_arg2) => ResultComputationExpression_ResultBuilder__Bind_764BA1D3(builder$0040_1, Result_sequence(map((item) => toValidatedOrderLine(fetchProductFromUnvalidatedOrderItem(item, _arg2), item), unvalidatedOrderLines)), (_arg3) => ResultComputationExpression_ResultBuilder__Return_1505(builder$0040_1, _arg3)))))));
    }));
}

export function calculateBasketPrice(basket) {
    return Types_PriceModule_create(reduce((acc, product) => op_Addition(acc, product), map_1((x) => Types_PriceModule_value(x.Price), basket)));
}

export function taxPercentage(taxType) {
    switch (taxType.tag) {
        case 0: {
            return fromParts(20, 0, 0, false, 0);
        }
        case 1: {
            return fromParts(6, 0, 0, false, 0);
        }
        default: {
            return fromParts(40, 0, 0, false, 0);
        }
    }
}

export function calculateTaxPercentage(taxType, total) {
    return op_Multiply(op_Division(total, fromParts(100, 0, 0, false, 0)), taxPercentage(taxType));
}

export function calculateTax(taxType, basket) {
    return calculateTaxPercentage(taxType, reduce((l, r) => op_Addition(l, r), map_1((x) => Types_PriceModule_value(x.Price), basket)));
}

export function calculateTaxes(basket) {
    return map_1((tupledArg) => [(basket_1) => calculateTax(tupledArg[0], basket_1), tupledArg[1]], groupBy((x) => x.Product.TaxTariff, basket, {
        Equals: (x_1, y) => equals(x_1, y),
        GetHashCode: (x_1) => safeHash(x_1),
    }));
}

export function validateOrder(validateBasket_1, unvalidatedOrder) {
    const builder$0040 = asyncResult;
    return AsyncResultComputationExpression_AsyncResultBuilder__Run_FCFD9EF(builder$0040, AsyncResultComputationExpression_AsyncResultBuilder__Delay_1505(builder$0040, () => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(builder$0040, validateBasket_1(unvalidatedOrder.Items), (_arg1) => {
        const items = _arg1;
        return AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(builder$0040, AsyncResult_ofResult(calculateBasketPrice(items)), (_arg2) => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(builder$0040, AsyncResult_ofResult(validateAddress(unvalidatedOrder.Address)), (_arg3) => AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(builder$0040, new ValidatedOrder(newGuid(), items, _arg2, _arg3))));
    })));
}

export function ToOrderItemDto(validatedOrder) {
    return toList(map_1((x) => {
        const Quantity = Types_UnitQuantityModule_value(x.Quantity) | 0;
        return new OrderItemDto(x.Product.ProductId, parse(x.Product.KioskId), Quantity, Types_PriceModule_value(x.Price), x.Product.ProductTitle, x.Product.PricePerPiece);
    }, validatedOrder));
}

export function ToOrderDto(validatedOrder) {
    let Street, HouseNumber;
    return new OrderDto(validatedOrder.OrderId, ToOrderItemDto(validatedOrder.Items), (Street = Types_String250Module_value(validatedOrder.Address.Street), (HouseNumber = Types_String50Module_value(validatedOrder.Address.HouseNumber), new AddressDto(Types_String50Module_value(validatedOrder.Address.Zipcode), Street, HouseNumber, Types_String250Module_value(validatedOrder.Address.City), Types_String50Module_value(validatedOrder.Address.Province)))));
}

