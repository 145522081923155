import React, { createContext, useEffect, useReducer } from "react"
import { validateBasketClientSide } from "../../domain/Fairsy.Domain/Order.Client.fs.js"
import { api } from "../settings"

export interface BasketUnit {
  productId: string
  kioskId: string
  quantity: number
  price?: number
  name?: string
  pricePerPiece?: number
}
export interface State {
  basket: BasketUnit[]
  validatedBasket?: BasketUnit[]
}

const reducer = (state: State, newState: Partial<State>): State => {
  return { ...state, ...newState }
}
const CheckoutContext = createContext<{
  state: State | undefined
  dispatch: React.Dispatch<Partial<State>>
}>({} as any)

const { Provider } = CheckoutContext

export const CheckoutProvider = ({
  children,
  basket,
  basketHash,
}: {
  children: any
  basket: BasketUnit[]
  basketHash?: string
}) => {
  const [state, dispatch] = useReducer(reducer, {
    basket: [],
  })
  console.log(basket)
  useEffect(() => {
    if (basket.length) {
      validateBasketClientSide(
        basket.map(x => ({
          KioskId: x.kioskId,
          ProductId: x.productId,
          Quantity: x.quantity,
        })),
        (validatedBasket: any) => {
          console.log("end product", validatedBasket)
          if (validatedBasket.length) {
            dispatch({ validatedBasket, basket })
          }
        },
        api
      )
    }
    // apiFetch("/api/OrderBasketValidateFunction", {
    //   body: JSON.stringify(
    //     basket.map(x => ({
    //       KioskId: x.kioskId,
    //       ProductId: x.productId,
    //       Quantity: x.quantity,
    //     }))
    //   ),
    // })
    //   .then(x => x.json())
    //   .then(validatedBasket => dispatch({ validatedBasket, basket }))
  }, [basketHash, basket])

  const value = { state, dispatch }

  return <Provider value={value}>{children}</Provider>
}

export const UserConsumer = CheckoutContext.Consumer

export default CheckoutContext
