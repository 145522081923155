import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
} from "@chakra-ui/react"
import React, { useContext } from "react"
import { useForm } from "react-hook-form"
import CheckoutContext from "../../context/checkoutContext"
import { apiFetch } from "../../utility/userFetch"
export const BasketInformationForm = () => {
  const { register, handleSubmit, errors } = useForm()

  const { state } = useContext(CheckoutContext)
  return (
    <form
      onSubmit={handleSubmit(form => {
        apiFetch("OrderFunction", {
          body: JSON.stringify({
            Items:
              state?.basket.map(x => ({
                ProductId: x.productId,
                KioskId: x.kioskId,
                Quantity: x.quantity,
              })) ?? [],

            Email: form.email,
            Address: {
              Zipcode: form.zipCode,
              Street: "Duckweg 44",
              HouseNumber: form.houseNumber,
              City: "Den Haag",
              Province: "Zuid-Holland",
            },
          }),
        }).then(x => alert("ok"))
      })}
    >
      <div style={{ margin: "1rem 0" }}>
        <FormControl>
          <FormLabel htmlFor="naam">Uw naam</FormLabel>
          <Input
            type="text"
            aria-describedby="naam-helper-text"
            ref={register({ required: true })}
            placeholder="naam"
            name="naam"
          />
          <FormHelperText id="naam-helper-text"></FormHelperText>
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="email">Email adres</FormLabel>
          <Input
            name="email"
            type="email"
            id="email"
            placeholder="jandevries@dolfijn.nl"
            aria-describedby="email-helper-text"
            ref={register({ required: true })}
          />
          <FormHelperText id="email-helper-text"></FormHelperText>
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="zipCode">Postcode</FormLabel>
          <Input
            name="zipCode"
            type="text"
            id="zipCode"
            aria-describedby="zipCode-helper-text"
            ref={register({ required: true })}
          />
          <FormHelperText id="zipCode-helper-text"></FormHelperText>
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="HouseNumber">Huisnummer</FormLabel>
          <Input
            name="houseNumber"
            type="text"
            id="HouseNumber"
            aria-describedby="HouseNumber-helper-text"
            ref={register({ required: true })}
          />
          <FormHelperText id="zipCode-helper-text"></FormHelperText>
        </FormControl>
      </div>
      <Button variantColor="teal" size="md" type="submit">
        Verstuur
      </Button>
    </form>
  )
}
